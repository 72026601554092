import React, { useState } from 'react';

const DocumentPreview = ({ url }: any) => {
  const [fileType, setFileType] = useState('');

  // Function to determine the file type
  const getFileType = (url: any) => {
    const extension = url?.split('.').pop().toLowerCase();
    return extension;
  };

  // Set the file type based on the URL
  React.useEffect(() => {
    setFileType(getFileType(url));
  }, [url]);

  url && console.log('getFileType', getFileType(url))

  return (
    <div style={{height: 'calc(100% - 46px)'}}>
      {fileType.includes('pdf') ? (
        <iframe
          src={url}
          title="PDF Preview"
          style={{ width: '100%', height: '100%' }}
          // frameBorder="0"
        />
      ) : fileType?.includes('jpg') || fileType?.includes('jpeg') || fileType?.includes('png') || fileType?.includes('gif') ? (
        <img src={url} alt="Preview" style={{ width: '100%', height: '100%' }} />
      ) : (
        // <div>
        //   <iframe
        //     src={url}
        //     title="Document Preview"
        //     style={{ width: '100%', height: '100%' }}
        //     // frameBorder="0"
        //   />
        //   <p>This document type cannot be previewed directly.</p>
        // </div>
        null
      )}
    </div>
  );
};

export default DocumentPreview;
