import React from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { useStore } from 'react-redux';
import { APPROVED_STATUS_ID, HANDED_OVER_STATUS_ID, IN_REVIEW_STATUS_ID } from '../../../types/projectMolecule';
import { isTeamPlanboardMyEnv } from '../../../shared/util';

const CommandBarRight = () => {
    const { t } = useTranslation();
    const newClick = () => {
        messageService.sendMessage({ isNewMYDP: true });
        // props.createNew(true);
    };
    const saveFormData = () => {
        messageService.sendMessage({ saveMYDPData: true });
    };
    const handleRemove = () => {
        // props.handleRemove();
    };

    const store = useStore();
    const isApprovedPmol = (store.getState()?.pmol?.formData?.statusId == APPROVED_STATUS_ID || store.getState()?.pmol?.formData?.statusId == HANDED_OVER_STATUS_ID)
    const isInReviewPmol = (store.getState()?.pmol?.formData?.statusId == IN_REVIEW_STATUS_ID)
    const isLoading = (store.getState()?.pmol?.showProgressBar)

    const getItems = () => {
        return [
            {
                key: 'new',
                text: t('new'),
                iconProps: { iconName: 'Add' },
                split: true,
                subMenuProps: {
                    items: [
                        { key: 'regular',
                            text: t('clientName'),
                            iconProps: { iconName: 'Add' },
                            // onClick: () => handleRegular()
                        },
                        {
                            key: 'subContract',
                            text: t('subContract'),
                            iconProps: { iconName: 'Add' },
                            // onClick: () => handleSubContract(),
                        },
                    ],
                },
                disabled: true,
                // onClick: () => handleRegular(),
            },
            {
                key: 'save',
                text: t('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => { saveFormData(); },
                disabled: isApprovedPmol || isLoading,
            },
            {
                key: 'saveAndClose',
                text: t('saveAndClose'),
                iconProps: { iconName: 'SaveAndClose' },
                onClick: () => {
                    saveFormData();
                    messageService.sendMessage({ hideDocumentPane: true, documentPaneType: 1 });
                },
                disabled: isApprovedPmol || isLoading,
            },
            {
                key: 'Remove',
                onClick: () => { handleRemove(); },
                name: t('remove'),
                iconProps: { iconName: 'Delete' },
                disabled: true
            },
            {
                key: 'approve',
                text: t('approve'),
                iconProps: { iconName: 'ActivateOrders' },
                onClick: () => {
                    messageService.sendMessage({ approvePmol: true });
                    if(isTeamPlanboardMyEnv()){
                        messageService.sendMessage({ reloadList: true });
                    }
                },
                disabled: !isInReviewPmol || isLoading,
            },
            {
                key: 'export',
                text: t('export'),
                iconProps: { iconName: 'Download' },
                split: true,
                subMenuProps: {
                    items: [
                        {
                            key: 'exportNl',
                            text: t('exportDutch'),
                            iconProps: { iconName: 'Download' },
                            onClick: () => {
                                messageService.sendMessage({ exportPmolNL: true });
                            },
                        },
                        {
                            key: 'exportEn',
                            text: t('exportEnglish'),
                            iconProps: { iconName: 'Download' },
                            onClick: () => {
                                messageService.sendMessage({ exportPmolEN: true });
                            },
                        },
                    ],
                },
                onClick: () => {
                    messageService.sendMessage({ exportPmolNL: true });
                },
            },
        ];
    };

    return (
        <div>
            <CommandBar
                items={getItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
        </div>
    );
};

export default CommandBarRight;
