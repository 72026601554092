import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import ImagePreview from './ImagePreview';
import client from '../../api';
import { isCuProjectPlanning, isPlanBoards, isPlanning, PMOL_PBS_HEADER_SECTION_LIST } from '../util';
import { SAVE_PBS_COMPOSITION_IMAGES } from '../endpoints';
import { useStore } from 'react-redux';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const uploadIcon: IIconProps = {
  iconName: 'Upload',
  styles: iconButtonStyles,
};

const copyIcon: IIconProps = {
  iconName: 'Copy',
  styles: iconButtonStyles,
};
const removeIconStyles = {
  root: {
      color: uPrinceTheme.palette.black,
      width: 17,
      minWidth: 17,
      height: 15,
      paddingRight: 1,
      paddingLeft: 1,
      paddingTop: 1,
      paddingBottom: 1
  },
  rootHovered: { color: theme.palette.neutralDark }
};
const removeIcon: IIconProps = {
  iconName: 'Delete',
  styles: removeIconStyles
};
const stackStyles: IStackStyles = { root: { padding: 0, boxSizing:'border-box', justifyContent: 'flex-end' } };
const firstStackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
    marginRight: 'auto',
  },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
});

const CustomFileUploader = (props: {
  isImage?: boolean;
  label: any;
  uploadedImages: any;
  uploadUrl: string;
  onSaveSuccess: (response: any) => void;
  onSaveError?: () => void;
  isDelete?: boolean;
  handleImageDelete?: (id: string) => void;
  renameSuccess?:() => void;
  disable?: boolean;
  checkedItems?: string[];
  productId?: any;
  showCopyToPbs?:boolean;
  showCheckbox?:boolean;
  showRename?:boolean;
  sectionHeading?:string;
  isDragEnabled?: boolean
  showDelete?:boolean;
  pmolId?: string;
  pbsId?: string;
  updateDocument?:(item: any, isDelete: boolean) => void,
}) => {
  const { t } = useTranslation();
  const store = useStore();  
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [transition, setTransition]: any = useState(false);
  const [checkedCopyItems, setCheckedCopyItems]: any = useState<any[]>([]);
  const [isDisabledCTPBS, setIsDisabledCTPBS] = useState<boolean>(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState<boolean>(false);
  const [deletedImageIds, setDeletedImageIds] = useState<any[]>([]);
  const inputFileRef = useRef<any>(null);

  useEffect(() => {
    if (props.isDragEnabled) {
      setIsDragAndDropEnabled(props.isDragEnabled)
    }
  }, [props.isDragEnabled])
  
  const handleCheckedItemsUpdate = (updatedCheckedItems: any) => {
    let data = [];
    setCheckedCopyItems((prevItems: any) => {
      const existingIndex = prevItems.findIndex((detail: any) => detail.imageId === updatedCheckedItems.imageId);
      if (existingIndex !== -1) {
          prevItems.splice(existingIndex, 1);
      }
      data = existingIndex === -1 ? [...prevItems, updatedCheckedItems]: prevItems;
      return data;
    });
    setIsDisabledCTPBS(data?.length === 0);
  };

  useEffect(() => {
    setFiles(props.uploadedImages);
    if(props.uploadedImages.length === 0){
        setIsDisabledCTPBS(true);
    }
  }, [props.uploadedImages]);

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const handleDelete = () => {
    const imageIds = checkedCopyItems.map((data : any) => data.imageId);
    const updatedFiles = selectedFiles.filter((file: any) => !imageIds.includes(file.id));

    let payload = {
      imageId: [] as string[],
      type: "",
      pmolId: null as string | null,
      pbsId: null as string | null,
    } || null;

    if(isPlanBoards() && props?.pmolId){
        const journalImgIds = checkedCopyItems.filter((item: any) => item.isJournal).map((item: any) => item.imageId);
        const extraworkIds = checkedCopyItems.filter((item: any) => item.isExtrawork).map((item: any) => item.imageId);
        if(journalImgIds && journalImgIds?.length>0){
          payload = {
            imageId: journalImgIds,
            type: "journal",
            pmolId: props.pmolId,
            pbsId: null,
          }
        } else if(extraworkIds && extraworkIds?.length>0){
          payload = {
            imageId: extraworkIds,
            type: "extraWork",
            pmolId: props.pmolId,
            pbsId: null,
          }
        }
    } else if((isPlanning() || isCuProjectPlanning()) && props?.pbsId){
        payload = {
          imageId: imageIds,
          type: "product",
          pmolId: null,
          pbsId: props.pbsId,
        }
    }

    if(payload){
      client.delete('Pmol/RemoveImage', { data: payload}).then(
        (response: any) => {
          setFiles(updatedFiles);
          if(props.updateDocument){
              if(payload.type === "extraWork"){
                  props.updateDocument(updatedFiles, false);
              } else if(payload.type === "journal"){
                  const journalDocuments = selectedFiles.map((file: any) => {
                    if (imageIds.includes(file.id)) {
                      return {
                        ...file,
                        isDelete: true,
                      };
                    }
                    return file;
                  })
                props.updateDocument(journalDocuments, false);
              } else if(payload.type === "product"){
                  props.updateDocument(null ,true);
              }
           }
           setCheckedCopyItems([]);
           setIsDisabledCTPBS(false);
        },
        (error: any) => {
          alert("Image remove not working");
        },
      );
    }
  };

  const handleCopyToPBSClick = () => {
    const journalImgIds = checkedCopyItems.filter((item: any) => item.isJournal).map((item: any) => item.imageId);
    const extraworkIds = checkedCopyItems.filter((item: any) => item.isExtrawork).map((item: any) => item.imageId);
    const pictureList = checkedCopyItems.map((item: any) => ({
      link: item.link,
      type: item.type
    }));
    const copyImgData = {
      id: "",
      description: "",
      pbsProductId: props.productId,
      pictureList: pictureList
    }    

    if(copyImgData && copyImgData.pbsProductId!=null && copyImgData.pictureList.length>0){
      copyToProductComposition(copyImgData);
    }

    if(extraworkIds && extraworkIds.length>0){
      const idList = {
        imageIds: extraworkIds
      }
      approveExtraImages(idList);
    }

    if(journalImgIds && journalImgIds.length>0){
      const idList = {
        imageIds: journalImgIds
      }
      approveJournalImages(idList);
    }

    const currentFiles = selectedFiles.map((item: any) => {
      if(extraworkIds?.includes(item.id) || journalImgIds?.includes(item.id)){
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null, isApproved : true, type: item.type};
      }
      else{
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null, isApproved : item.isApproved, type: item.type};
      }
    });

    setFiles(currentFiles);
    if(props.updateDocument){
      props?.updateDocument(currentFiles, false);
    }
  };

  const copyToProductComposition =(copyImgData: any) =>{
    client.post("Pbs/CopyPicturesToComposition", copyImgData).then(
      (response: any) => {
        
      },

      (error: any) => {
        alert("not working");
      },
    );
  }

  const approveExtraImages =(imageIds: any) =>{
    client.post("Pmol/ApproveImages", imageIds).then(
      (response: any) => {
        setCheckedCopyItems([]);
      },

      (error: any) => {
        alert("Extra not working");
      },
    );
  }

  const approveJournalImages =(imageIds: any) =>{
    client.post("PmolJournal/ApproveJournalImages", imageIds).then(
      (response: any) => {
        setCheckedCopyItems([]);
      },

      (error: any) => {
        alert("Journal not working");
      },
    );
  }


  const saveProductCompositionImages = async (imageList : any, imageId: any) => {

      let payload = {
        id: "",
        description: "",
        pbsProductId: props.pbsId,
        pictureList: [imageList]
      }

      await client.post(SAVE_PBS_COMPOSITION_IMAGES, payload).then(async (response: any) => {
        await props.onSaveSuccess(imageId);
      })
  }

  const uploadFiles = async (file: File) => {
    // files && context.uploadItems(files);
    if (selectedFiles) {
      const currentFiles = selectedFiles.map((item: any) => {
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null };
      });
      // if (files && files.length>0) {
        // for(const file of files){
          let parts = file?.name.split('.');
          const size = file?.size;
          // alert(size);
          setFiles(
            currentFiles.concat([
              { image: URL.createObjectURL(file), id: null, isLast: true, extension: parts[parts.length - 1] },
            ]),
          );
          setTransition(true);
          await saveImage(file, currentFiles);
        // }
      // }
    }
  };

  const saveImage = async (files: any, currentFiles: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const data = new FormData();
    if (files) {
        data.append('image', files);
    }
    await client.post(props.uploadUrl, data, { headers: headers }).then(
      async (response: any) => {
        setTransition(false);
        if(props.pbsId && props.sectionHeading === PMOL_PBS_HEADER_SECTION_LIST.PRODUCT){
          let newImage = {
            link : response.data.result,
            type: "1"
          }
          saveProductCompositionImages(newImage, response.data.result);
        } else {
          await props.onSaveSuccess(response.data.result);
        }        
      },

      (error: any) => {
        alert(JSON.stringify(error));
        setTransition(false);
        setFiles(props.uploadedImages);
      },
    );
  };

  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    // const droppedFiles = Array.from(e.dataTransfer.files);
    for (const file of e.dataTransfer.files){
      isDragAndDropEnabled && uploadFiles(file)
    }    
  };
 
  const deleteImage = (id: string, section : string) => {
    const extraWorkImages = store.getState()?.pmol?.extraWork?.imageList?.map((item:any) => {return {...item, image: item.link, isExtrawork: true}});
    const journalImages = store.getState()?.pmol?.journal?.pictureList?.map((item: any) => {return {...item, image: item.link, isJournal: true}});
    const handshakeImages = store.getState()?.pmol?.handshakeDocs?.map((item: any) => {return {...item, isHandshake: true}});
    let deleteId = deletedImageIds 
    deleteId.push(id);
    setDeletedImageIds(deleteId);

    if(props.updateDocument){
        if(section === "extraWork"){
            const updatedFiles = extraWorkImages?.filter((file: any) => !deleteId?.includes(file.id));
            setFiles(updatedFiles);
            props.updateDocument(updatedFiles, true);
        } else if(section === "journal"){
            const journalImageList = journalImages.map((file: any) => {
              if (deleteId.includes(file.id)) {
                return {
                  ...file,
                  isDelete: true,
              }}
              return file;
            })
            setFiles(journalImageList);
            props.updateDocument(journalImageList, false);
        } else if(section === "handShake"){
          const updatedFiles = handshakeImages?.filter((file: any) => !deleteId?.includes(file.id));
          setFiles(updatedFiles);
          props.updateDocument(updatedFiles, true);
        } else if(section === "product"){
          props.updateDocument(id, true);
        }
    }
  }
  
  return (    
    <div  className={`dropzone ${dragging ? 'dragging' : ''} input-file`} 
    onDragEnter={handleDragEnter}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    onDrop={handleDrop}>
      <div>
        <input
          style={{ display: 'none' }}
          ref={inputFileRef}
          type="file"
          id='file'
          accept={props.isImage ? 'image/*' : '*'}
          multiple={true}
          onChange={async (e) => {
            if(e.target.files && e.target.files.length>0){
              for(const file of e.target.files){
                await uploadFiles(file);
              }
            }
          }}
        />
      </div>
      <Stack
        horizontal
        // horizontalAlign="space-between"
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack.Item styles={firstStackItemStyles}>
          <ActionButton
          className={classNames.actionButton}
          >
            <Label>{props.label}</Label>
          </ActionButton>          
        </Stack.Item>
        <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={uploadIcon}
              allowDisabledFocus
              // disabled={props.disable}
              onClick={() => {
                showFileDialog();
              }}
            >
              <Label className={classNames.actionButton}>{t('upload')}</Label>
            </ActionButton>
        </Stack.Item>
        {props.showCopyToPbs && (
        <Stack.Item styles={stackEditbarItemStyles}>
          <ActionButton
            className={classNames.actionButton}
            iconProps={copyIcon}
            allowDisabledFocus={isDisabledCTPBS}
            disabled={isDisabledCTPBS}
            style={ {opacity: isDisabledCTPBS ? 0.5 : 1} }
            onClick={() => {
              handleCopyToPBSClick();
            }}
          >
          <Label className={classNames.actionButton}>{t('Copy to PBS')}</Label>
          </ActionButton>
        </Stack.Item>
        )}
         {/* {props.showDelete && (
        <Stack.Item styles={stackEditbarItemStyles}>
          <ActionButton
            className={classNames.actionButton}
            iconProps={removeIcon}
            allowDisabledFocus={isDisabledCTPBS}
            disabled={isDisabledCTPBS}
            style={ {opacity: isDisabledCTPBS ? 0.5 : 1} }
            onClick={() => {
              handleDelete();
            }}
          >
          <Label className={classNames.actionButton}>{t('remove')}</Label>
          </ActionButton>        
        </Stack.Item>
        )} */}
      </Stack>
      <div>
        <ImagePreview
          transition={transition}
          uploadedImages={selectedFiles}
          handleImageDelete={(id: string) => {
            if (props.handleImageDelete) {
              props.handleImageDelete(id);
            }
          } }
          disable={props.disable}
          isDelete={props.isDelete}
          onCheckedItemsUpdate={handleCheckedItemsUpdate}
          showCheckbox={props.showCheckbox}
          showRename={props.showRename}
          sectionHeading={props.sectionHeading} 
          renameSuccess={(item: any) => {
            if(item && props.renameSuccess){
              props.renameSuccess();
            }
          }}
          showDelete ={props.showDelete}
          pmolId ={props.pmolId}
          pbsId = {props.pbsId}
          deleteImage = {(id: string, section: string) => {
            if(id && section){
              deleteImage(id, section)
            }
          }}
          />
      </div>
    </div>
  );
};

export default CustomFileUploader;

export interface ImageDetail {
  journalWork: {
      imageId: string[]; 
      pmolId: string;
      journalId: string;
      journalWorkId: string;
  };
  extraWork: {
      imageId: string[];
      pmolId: string;
      journalId: string;
      extraWorkId: string;
  };
}
